import { memo, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { responsesFuncMap } from "../../services/storedResponseServices";
import { formatAddress, formatDate } from "../../helpers/utils";
import person from "../../assets/icons/person.svg";
import assignment from "../../assets/icons/assignment.svg";
import bank from "../../assets/icons/bank.svg";
import StatusBadge from "../StatusBadge";
import card from "../../assets/icons/card.svg";
import {
  CmcStatus,
  emphasisByStatuses,
  StatusBadgeTextEnum,
} from "../../constants";
import { Variant } from "react-bootstrap/esm/types";
import { UserType } from "../../types/user";
import { DepositBankDetail } from "../../types/depositBankDetail";
import { getDepositBankDetail } from "../../services/userServices";
import ComponentLoader from "../ComponentLoader";

import styles from "../../styles/user.module.scss";
import { UserCardDetail } from "./CardDetail";

export const CardTextItem = ({
  topic,
  content,
  dataTestId,
}: {
  topic: string;
  content: string | undefined;
  dataTestId?: string;
}) => (
  <span className={styles.cardTextItem} data-testid={dataTestId}>
    <b>{topic}</b>: {content || " Not Given"}
  </span>
);
export const CardStatusItem = ({
  topic,
  value,
  emphasis,
}: {
  topic: string;
  value: keyof typeof StatusBadgeTextEnum;
  emphasis: Variant;
}) => (
  <span className={styles.cardTextItem}>
    <b>{topic}</b>:{" "}
    <StatusBadge status={StatusBadgeTextEnum[value]} emphasis={emphasis} />
  </span>
);

export const transformHeadersMap: Partial<
  Record<keyof typeof responsesFuncMap, Record<string, string>>
> = {
  CRS: { tin: "TIN", country: "Country", noTinIssuedReason: "No TIN Reason" },
};

function UserDetails({ data }: { data: UserType }) {
  const { userId } = useParams();
  const {
    isLoading,
    data: depositBankDetails = [],
    refetch,
  } = useQuery<DepositBankDetail[], Error>(
    ["get-deposit-bank-detail", userId],
    () => getDepositBankDetail(userId || "")
  );

  useEffect(() => {
    refetch();
  }, [data.is_disabled, refetch]);

  const getCmcStatus = (accepted?: boolean): CmcStatus => {
    switch (accepted) {
      case true:
        return "ACCEPT";
      case false:
        return "REJECTED";
      default:
        return "PENDING";
    }
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <Card className={styles.detailsCard}>
            <Card.Body>
              <Card.Title className={styles.titleInBox}>
                <img src={person} alt="person" />
                <span>Personal details</span>
              </Card.Title>
              <Card.Text className={styles.cardText}>
                <CardTextItem topic="Email" content={data.email} />
                <CardTextItem
                  topic="Mobile"
                  content={
                    data.mobile
                      ? `+${data.mobile_country_code}${data.mobile} (${
                          data.phone_number_verified
                            ? "Verified"
                            : "Not verified"
                        })`
                      : "-"
                  }
                />
                <CardTextItem topic="ID number" content={data.id_number} />
                <CardTextItem
                  topic="Date of birth"
                  content={
                    data.date_of_birth && formatDate(data.date_of_birth || "")
                  }
                />
                <CardTextItem topic="Sex" content={data.sex} />
                <CardTextItem topic="Nationality" content={data.nationality} />
                <CardTextItem
                  topic="Address"
                  content={formatAddress({
                    country: data.addr_country,
                    postalCode: data.addr_postal,
                    addressLine1: data.addr_line_1,
                    addressLine2: data.addr_line_2,
                    addressLine3: data.addr_line_3,
                  })}
                />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className={styles.detailsCard}>
            <Card.Body>
              <Card.Title className={styles.titleInBox}>
                <img src={assignment} alt="person" />
                <span>Statuses</span>
              </Card.Title>
              <Card.Text className={styles.cardText}>
                <Row>
                  <Col>
                    <CardStatusItem
                      topic="Verification"
                      value={data.verification_status}
                      emphasis={
                        emphasisByStatuses.verification_status[
                          data.verification_status
                        ]
                      }
                    />
                    <CardTextItem
                      topic="Verification sub-status"
                      content={data.verification_sub_status || "-"}
                    />
                    <CardStatusItem
                      topic="Screening"
                      value={data.sanctions_status}
                      emphasis={
                        emphasisByStatuses.sanctions_status[
                          data.sanctions_status
                        ]
                      }
                    />
                    <CardStatusItem
                      topic="Proof of address"
                      value={data.proof_of_address_status}
                      emphasis={
                        emphasisByStatuses.proof_of_address_status[
                          data.proof_of_address_status
                        ]
                      }
                    />
                    <CardStatusItem
                      topic="Adverse media"
                      value={data.adverse_media_status}
                      emphasis={
                        emphasisByStatuses.adverse_media_status[
                          data.adverse_media_status
                        ]
                      }
                    />
                    <CardStatusItem
                      topic="PEP screening"
                      value={data.pep_screening_status}
                      emphasis={
                        emphasisByStatuses.pep_screening_status[
                          data.pep_screening_status
                        ]
                      }
                    />
                    <CardStatusItem
                      topic="EDD"
                      value={data.edd_status}
                      emphasis={emphasisByStatuses.edd_status[data.edd_status]}
                    />
                  </Col>
                  <Col>
                    <Card.Text className={styles.cardText}>
                      <CardStatusItem
                        topic="CKA"
                        value={data.cka_status}
                        emphasis={
                          emphasisByStatuses.cka_status[data.cka_status]
                        }
                      />
                      <CardStatusItem
                        topic="AI"
                        value={data.ai_status}
                        emphasis={emphasisByStatuses.ai_status[data.ai_status]}
                      />
                      <CardStatusItem
                        topic="CRS"
                        value={data.crs_status}
                        emphasis={
                          emphasisByStatuses.crs_status[data.crs_status]
                        }
                      />
                      <CardStatusItem
                        topic="FNA Status"
                        value={data.fnaStatus}
                        emphasis={emphasisByStatuses.fnaStatus[data.fnaStatus]}
                      />
                      <CardTextItem
                        topic="FNA Recommendation Accepted Date"
                        content={
                          data.fna_accept_recommendation_date
                            ? formatDate(
                                data.fna_accept_recommendation_date,
                                "d MMMM yyyy h:mma",
                                "Asia/Singapore"
                              ).concat(" SGT")
                            : "Not Accepted"
                        }
                      />
                      <CardStatusItem
                        topic="Change of Manager Consent Status"
                        value={getCmcStatus(data.changeManager.accepted)}
                        emphasis={
                          emphasisByStatuses.cmc_status[
                            getCmcStatus(data.changeManager.accepted)
                          ]
                        }
                      />
                      <CardTextItem
                        topic="Change of Manager Decision Date"
                        content={
                          data.changeManager.decisionDate
                            ? formatDate(
                                data.changeManager.decisionDate,
                                "d MMMM yyyy h:mma",
                                "Asia/Singapore"
                              ).concat(" SGT")
                            : " "
                        }
                      />
                    </Card.Text>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className={styles.detailsCard}>
            <Card.Body>
              <Card.Title className={styles.titleInBox}>
                <img src={bank} alt="bank" />
                <span>Virtual account</span>
              </Card.Title>
              {isLoading ? (
                <ComponentLoader isLoading={isLoading} />
              ) : (
                <Card.Text className={styles.cardText}>
                  <Row>
                    <Col>
                      {depositBankDetails.map((account) => {
                        return (
                          <CardTextItem
                            dataTestId={`deposit-bank-account-${account.accountNumber}`}
                            topic="Account number"
                            content={account.accountNumber}
                            key={account.accountNumber}
                          />
                        );
                      })}
                    </Col>
                  </Row>
                </Card.Text>
              )}
            </Card.Body>
          </Card>
          <Card className={styles.detailsCard}>
            <Card.Body>
              <Card.Title className={styles.titleInBox}>
                <img src={card} alt="card-details" />
                <span>Card details</span>
              </Card.Title>
              <UserCardDetail userId={userId || ""} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default memo(UserDetails);
