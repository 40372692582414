import { memo } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { Row, Col, Card } from "react-bootstrap";

import ComponentLoader from "../../ComponentLoader";
import { getCardDetailByUserId } from "../../../services/userServices";
import ErrorPage from "../../ErrorPage";
import { QueryKeyEnum } from "../../../types";
import { HTTP_CODES } from "../../../constants";

import styles from "../../../styles/user.module.scss";
import { CardDetail } from "../../../types/CardDetail";
import { CardTextItem } from "..";

export interface CardDetailProps {
  userId: string;
}

export function UserCardDetail(props: CardDetailProps) {
  const { isLoading, error, data } = useQuery<CardDetail, Error>(
    [QueryKeyEnum.GetCardDetail, props.userId],
    () => getCardDetailByUserId(props.userId)
  );

  if (isLoading) return <ComponentLoader isLoading={isLoading} />;

  if (
    axios.isAxiosError(error) &&
    error.response?.status === HTTP_CODES.NOT_FOUND
  ) {
    return (
      <Card.Text className={styles.cardTextItem}>
        Digital card not activated yet
      </Card.Text>
    );
  }

  if (error || !data) return <ErrorPage />;

  return (
    <>
      <Card.Text className={styles.cardText}>
        <Row>
          <Col>
            <CardTextItem
              topic="Cardholder name"
              content={data.cardholderName}
            />
            <CardTextItem
              topic="Last 4 digits"
              content={data.cardNumberLastDigits}
            />
            <CardTextItem topic="Card status" content={data.status} />
            <CardTextItem
              topic="Fazz card opaque ID"
              content={data.cardOpaqueId}
            />
            <CardTextItem
              topic="Print request date"
              content={data.printRequestDate || "No physical card"}
            />
            <CardTextItem
              topic="Delivery address"
              content={data.deliveryAddress || "-"}
            />
          </Col>
        </Row>
      </Card.Text>
    </>
  );
}

export default memo(UserCardDetail);
